/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { themeConfig } from '@themed';
import {
  Layout, StaticSEO, CompactSectionContentStyles, SectionWithContent, Title, ContactButtonList, ImageLoader, Paragraph, Link,
} from '@core';
import { useTranslate } from '@modules';
import { ChildrenProps } from '@types';
import { styles } from './_contact.styles';

export default () => {
  const __ = useTranslate();

  return (
    <Layout>
      <StaticSEO pageId="contact" />

      <SectionWithContent sx={styles.container} data-cy="contact">
        <CompactSectionContentStyles sx={styles.compact}>
          <Title id="title" data-cy="contact-title" displayAs="h1">
            {__('pages.contact.title')}
          </Title>

          <Box id="owner-image" data-cy="contact-image-owner">
            <ImageLoader
              src={__(__('pages.contact.person.image.url'), true)}
              alt={__(__('pages.contact.person.image.alternativeText'), true)}
              sx={styles.image}
            />
          </Box>

          <GoogleMapLink id="map-desktop" data-cy="contact-map-image">
            <ImageLoader
              src={__('pages.contact.map.image.url')}
              alt={__('pages.contact.map.image.alternativeText')}
              sx={styles.image}
            />
          </GoogleMapLink>

          <GoogleMapLink id="map-mobile" data-cy="contact-map-image" />

          <Paragraph id="owner-text" data-cy="contact-paragraph-owner">
            <OwnerText />
          </Paragraph>

          <GoogleMapLink id="address-text" data-cy="contact-paragraph-address">
            <MdLocationOn color={themeConfig.colors.primary} size={40} />
            <Paragraph as="p">{__('pages.contact.address')}</Paragraph>
          </GoogleMapLink>

          <ContactButtonList
            id="calendly"
            data-cy="contact-button-calendly"
            calendar={__(__('pages.aboutus.founders.list.0.calendar'))}
            sx={styles.cta}
          />

          <ContactButtonList
            id="linkedin"
            data-cy="contact-button-linkedin"
            linkedin={__(__('pages.contact.person.linkedin'))}
            sx={styles.cta}
          />

          <ContactButtonList
            id="call"
            data-cy="contact-button-call"
            sx={{ ...styles.cta, gridArea: 'call' }}
            {...({ [__('pages.contact.person.ctaType')]: __(__('pages.contact.person.ctaText')) })}
          />

        </CompactSectionContentStyles>
      </SectionWithContent>

    </Layout>
  );
};

interface GoogleMapLinkProps extends ChildrenProps {
  id?: string;
  'data-cy'?: string;
}

const GoogleMapLink = ({ children, id, ...props }: GoogleMapLinkProps) => {
  const __ = useTranslate();

  return (
    <Link
      id={id}
      aria-label="Google map - Rasumofskygasse 26 - 1030 Wien"
      to={__('pages.contact.map.link')}
      target="_blank"
      {...props}
    >
      {children}
    </Link>
  );
};

const OwnerText = () => {
  const __ = useTranslate();

  return (
    <React.Fragment>
      {`${__(__('pages.contact.person.name'))}, ${__(__('pages.contact.person.title'))}`}
      {__('pages.contact.person.email', true) && <Styled.a href={`mailto:${__(__('pages.contact.person.email'))}`}>{__(__('pages.contact.person.email'))}</Styled.a>}
    </React.Fragment>
  );
};
