import { SxStyleProp } from 'theme-ui';
import map from '../images/contact/map-large.png';

export const styles = {
  container: {
    'p, hr': {
      m: '0 !important',
      p: '0 !important',
    },
  },
  compact: {
    display: 'grid',
    width: ['max-content', '100%'],
    gridTemplateColumns: ['max-content', '36fr 64fr', '1fr 1fr 1fr'],
    gap: [4, 4, 5],
    pt: 8,
    mb: [9, 0],
    gridTemplateAreas: [
      '"photo" "title" "owner" "calendly" "linkedin" "call" "address" "map"',
      '"photo title" "photo owner" "empty calendly" "empty linkedin" "empty call" "map address"',
      '"title photo map" "empty owner address" "calendly linkedin call"',
    ],

    '&:hover': {
      div: {
        variant: 'images.grayScaleHover',
      },
    },

    '#title': {
      fontSize: ['m', 'l', 'xl'],
      mb: t => [0, `-${t.fontSizes.l}`, 0],
      height: ['auto', 'auto', 'fullSize'],
    } as SxStyleProp,

    '#owner-image': {
      gridArea: 'photo',
    },

    '#owner-text': {
      gridArea: 'owner',
      p: 0,
      fontSize: ['xxs', 'xs'],
      textAlign: ['left', 'left', 'center'],
      display: 'block',
      a: {
        pt: ['3', '3', 0],
        fontSize: ['xxs', 'xs'],
        clear: 'both',
        display: 'block',
      } as SxStyleProp,
    } as SxStyleProp,

    '#address-text': {
      variant: 'flex.rowCenter',
      gridArea: 'address',
      fontSize: ['xxs', 'xs'],
      padding: 0,
      placeContent: ['flex-start', 'flex-start', 'center'],
      svg: {
        display: ['inline-block', 'none'],
      },
      p: {
        fontSize: ['xxs', 'xs'],
        textAlign: ['left', 'left', 'center'],
        p: 0,
      } as SxStyleProp,
      pb: [5, 0],
    } as SxStyleProp,

    '#calendly': {
      gridArea: 'calendly',
    },

    '#linkedin': {
      gridArea: 'linkedin',
    },

    '#call': {
      gridArea: 'call',
    },

    '#map-desktop': {
      gridArea: 'map',
      display: ['none', 'inline-block'],
      '&:before': {
        content: '""',
        display: 'none',
      },
    } as SxStyleProp,

    '#map-mobile': {
      gridArea: 'map',
      display: ['inline-block', 'none'],
      width: '100%',
      height: '100%',
      maxHeight: '160px',
      position: 'absolute',
      bottom: 'footerHeight',
      left: 0,
      zIndex: 55,
      backgroundImage: `url(${map})`,
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      filter: 'grayscale(100%)',
    },
  },

  image: {
    borderRadius: 'fullSize',
    width: t => [`${t.sizes.pacmanSmall} !important`, '185px !important'],
    m: ['none', 'auto'],
    variant: 'images.grayScale',
  } as SxStyleProp,

  cta: {
    variant: 'flex.center',
    justifyContent: ['flex-start', 'flex-start', 'center'],
    a: {
      flex: '1 1 auto',
      justifyContent: ['flex-start', 'flex-start', 'center'],
      maxWidth: '260px',
      minWidth: [0, 0, 0, '250px'],
      mr: 0,
      fontSize: ['xxs', 'xs'],
    },
  },
};
